import React from "react";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";

const GlobalFootnotes = ({ fields }) => {
  return (
    <div className="compatibility-global-footnotes">
      <div className="footnotes-content">
        {fields.Title.value ? (
          <span>
            <Text field={fields.Title} />
          </span>
        ) : null}
        <RichText field={fields.bodyCopy} />
      </div>
    </div>
  );
};

export default GlobalFootnotes;
